// import '../css/app.css';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

/**
 * a simple check to see if the user is using the LinkedIn app on iOS and 
 * force the user to open the page in Safari instead of in the native linkedin app 
*/ 

checkLinkedInApp();

function checkLinkedInApp() {
    const userAgent = window.navigator.userAgent;
    const url = window.location.href;
    if (userAgent.includes('Mobile') && (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes('LinkedInApp')) {
        window.location.href = 'x-safari-' + url;
        return
    }
}


import './bootstrap';

import './src/datadog';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import './Index';

// Bootstrap menu toggle
import './src/menu-toggle';

// Prevent explorer filters from closing
import './src/explorer-filters';

// Prevent explorer filters from closing
import './src/share';

// Invite guests form
import './src/guests';

// Initialize toast notifications
import './src/toasts';

// Initialize toast notifications
import './src/cookies';

// Simple validation for chef booking rejection
import './src/chef-jobs';

// Customise the Intercom widget
// import './utils/intercom';

// Section toggle
import './src/section-toggle';

// Close quote verification callback
import './src/close-quote-verification';

// Simple sqiper carousel
import './src/carousel';

// Simple form validation
import './src/form-validation';

// Add to homescreen logic
// import './src/add-to-home-screen';

//add simple support for background images:
document.addEventListener('lazybeforeunveil', function (e) {
    let bg = e.target.getAttribute('data-bg');

    if (bg) e.target.style.backgroundImage = 'url(' + bg + ')';
});

// Inertia
import './inertia';
